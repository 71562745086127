.pageWrap{
    width: 100%;
    h2{
        color: #222d39;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.3;
        margin-top: 0;
        margin-bottom: 20px;
        letter-spacing: 0;
    }
    p{
        margin-bottom: 40px;
        font-size: 22px;
        line-height: 1.6;
        color: #3e4854;
    }
}
.display-flex{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-left: -20px;
    .width-25{
        width: 25%;
        padding-left: 20px;
    }
}
.pricing-wrapper{
    padding: 20px;
    box-shadow: 0 1px 1px 0 rgb(34 45 57 / 5%);
    border-radius: 10px;
    border: 1px solid #d5dce3;
    text-align: left;
    .flex{
        display: flex;
        align-items: center;
    }
    .pricing-box{
        padding: 5px;
        justify-content:space-between;
        height: 100%;
        display: flex;
        flex-direction: column;
        .box-pricing{
            min-height: 80px;
        }
    }
    .pricing-title{
        margin-bottom: 5px;
        color: #222d39;
        font-size: 19px;
        font-weight:600;
        line-height: 1;
    }
    .pricing-details{
        line-height: 26px;
        font-size: 13px;
        color: #3e4854;
        font-weight: 400;
    }
    .mb-0{
        margin-bottom: 0;
    }
    .font-700{
        font-weight: 700;
    }
    .pricing-month{
        font-weight: 400;
        color: #222d39;
        font-size: 19px;
        margin-left: 4px;
    }
}
.btn-wrapper{
    height: 40px;
    line-height: 40px;
    min-width: 40px;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    color: #fff;
    fill: #aab5c5;
    background-color: #141162;
    white-space: nowrap;
    font-weight:600;
    cursor: pointer;
    text-align: center;
}
.pricing-list-wrapper{
    padding-top: 20px;
    padding-left: 10px;
    margin-bottom: 40px;
    .pricing-list{
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }
    .pricing-list-img{
        display: flex;
        img{
            width: 16px;
            height: 16px;
        }
    }
    .pricing-list-title{
        font-size: 13px;
        line-height: 26px;
        color: #3e4854;
        margin-left: 6px;
    }
}