.about{
    padding: 80px 0 34px 0;
    color: #000;
}
.about-title{
    margin-bottom: 60px;
    h2{
        margin-top: 0;
        margin-bottom: 20px;
        font-size: 38px;
        line-height: 1;
        letter-spacing: 0;
    }
    p{
        margin-bottom: 0;
        font-size: 18px;
        line-height: 1;
    }
}
.about-wrapper{
    display: flex;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 60px;
    .about-left{
        max-width: 40%;
        h2{
            margin: 0 0 20px 0;
            font-size: 38px;
            line-height: 1;
            letter-spacing: 0;
        }
        p{
            margin-bottom: 14px;
            font-size: 18px;
            line-height: 1.3;
        }
    }
    .about-right{
            max-width: 50%;
            border-radius: 20px;
            overflow: hidden;
            height: 550px;
            width: 100%;
        img{
            border-radius: 20px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}
.about-wrapper.right{
    flex-direction: row-reverse;
}