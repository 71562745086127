.hero {

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-inner {

	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

.bg-banner{
	background-image: url(../../../images/hero.png);
	background-size: 100%;
	background-position: center;
	color: #fff;

	.banner-title{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 30px;
		line-height: 170%;
		display: flex;
		align-items: center;
		text-align: center;
		color: #FFFFFF;
		width: 100%;
		max-width: 75%;
		margin-left: auto;
		margin-right: auto;
		text-transform: uppercase;
	}
	.flex{
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 47px;
	}
	.contact-btn{
		background-color: #FFFFFF;
		box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
		border-radius: 50px;
		padding: 15px 25px;
		@include font-family(Open);
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;
		color: #111111;
	}
}

@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}
