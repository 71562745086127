.features-tiles-item-image {
	display: inline-flex;
	border-radius: 50%;
	background-color: get-color(primary, 5);
}
.text-sm-title{
	font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 170%;
text-align: center;
color: #000000;
margin-bottom: 5px;
margin-top: 0;
}
.text-sm-para{
	@include font-family(Open);
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 170%;
	text-align: center;
	color: #212529;	
}