.section-inner {
    position: relative; // To always display inner elements above pseudo decorative stuff
    padding-top: $section--padding__mobile;
    padding-bottom: $section--padding__mobile;
}
.border-top{
    border-top:1px solid #D9D9D9;
}

.section-header {
	padding-bottom: $section-header--padding__mobile;
}

@include media( '>medium' ) {

    .section-inner {
        padding-top: $section--padding__desktop * 0.7;
        padding-bottom: $section--padding__desktop * 0.438;
    }

	.section-header {
		padding-bottom: $section-header--padding__desktop;
	}
}
