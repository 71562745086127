.contact-hero{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 80px 0;
    .contact-left,.contact-right{
        width: 100%;
    }
    .contact-right{
        padding-left: 30px;
    }
    .contact-left{
        h2{
            color: #222d39;
            font-size: 36px;
            font-weight: 700;
            line-height: 1.3;
            text-align: left;
            margin-top: 0;
            margin-bottom: 20px;
            letter-spacing: 0;
        }
    }
    .input-wrapper{
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-bottom: 20px;
        label{
            line-height: 26px;
            font-size: 15px;
            font-weight: 400;
            color: #000;
            margin-bottom: 10px;
        }
        input{
            height: 50px;
            font-size: 15px;
            line-height: 1;
            color: #3e4854;
            border-radius: 4px;
            background-color: #fff;
            width: 100%;
            padding: 0 16px;
            outline: 0;
            border: 1px solid #d5dce3;
        }
        textarea{
            min-height: 120px;
            font-size: 15px;
            line-height: 1;
            color: #3e4854;
            border-radius: 4px;
            background-color: #fff;
            width: 100%;
            padding: 14px 16px;
            outline: 0;
            border: 1px solid #d5dce3;
            resize: none;
        }
    }
}
.submit{
    width: 100%;
    height: 50px;
    background-color: #141162;
    color: #fff;
    border: 0;
    border-radius: 50px;
    font-size: 15px;
    line-height: 1;
    font-weight: 800;
}
@media only screen and (min-width: 770px){
    .contact-hero{
    .contact-left{
        width: 41.66667%;
    }
    .contact-right{
        width: 58.33333%;
    }
}
}