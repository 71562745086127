.testimonial {

	.section-inner {
		padding-top: $testimonial--padding-t__mobile;
		padding-bottom: $testimonial--padding-b__mobile;
	}

	.section-header {
		padding-bottom: $testimonial-header--padding__mobile;
	}

	.tiles-wrap {
		@if ( $testimonial-items--padding__mobile != null ) {
			margin-right: -($testimonial-items--padding__mobile * 0.5);
			margin-left: -($testimonial-items--padding__mobile * 0.5);
			margin-top: -($testimonial-items--padding__mobile * 0.5);

			&:last-of-type {
				margin-bottom: -($testimonial-items--padding__mobile * 0.5);
			}

			&:not(:last-of-type) {
				margin-bottom: ($testimonial-items--padding__mobile * 0.5);
			}
		}

		&.push-left {

			&::after {
				flex-basis: $testimonial-item--width;
				max-width: $testimonial-item--width;
				@if ( $testimonial-items--padding__mobile != null ) {
					padding-left: $testimonial-items--padding__mobile * 0.5;
					padding-right: $testimonial-items--padding__mobile * 0.5;
				}
			}
		}
	}

	.tiles-item {
		flex-basis: 100%;
		max-width: 100%;
		@if ( $testimonial-items--padding__mobile != null ) {
			padding: $testimonial-items--padding__mobile * 0.5;
		}
	}

	.tiles-item-inner {
		padding: 50px;
	}
	.img-wrapper{
		width: 100%;
		max-width: 50%;
		padding-right:25px ;
		img{
			width: 100%;
			height: 100%;
		}
	}
	.img-wrapper.left{
		padding-right:0px ;
		padding-left:25px ;
	}
	.heading-para{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 24px;
		line-height: 170%;
		color: #000000;
		margin-bottom: 5px;
	}
	.text-wrapper{
		padding-left:25px;
		.text-sm{
			@include font-family(Open);
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 170%;
			color: #212529;
		}
	}
	.text-wrapper.left{
		padding-left:0px;
		padding-right:25px;
	}
}

.testimonial-item-content {
	flex-grow: 1;
}

.testimonial-item-source {
	display: inline-flex; // to allow centering when using .content-center on a parent tag
	flex-wrap: wrap;
	align-items: center;
}

@include media( '>medium' ) {

	.testimonial {

		.section-inner {
			padding-top: $testimonial--padding-t__desktop;
			padding-bottom: $testimonial--padding-b__desktop;
		}

		.section-header {
			padding-bottom: $testimonial-header--padding__desktop;
		}

		@if ( $testimonial-items--padding__desktop != null ) {

			.tiles-wrap {
				margin-right: -($testimonial-items--padding__desktop * 0.5);
				margin-left: -($testimonial-items--padding__desktop * 0.5);
				margin-top: -($testimonial-items--padding__desktop * 0.5);

				&:last-of-type {
					margin-bottom: -($testimonial-items--padding__desktop * 0.5);
				}

				&:not(:last-of-type) {
					margin-bottom: ($testimonial-items--padding__desktop * 0.5);
				}

				&.push-left {

					&::after {
						padding-left: $testimonial-items--padding__desktop * 0.5;
						padding-right: $testimonial-items--padding__desktop * 0.5;
					}
				}
			}

			.tiles-item {
				padding: $testimonial-items--padding__desktop * 0.5;
			}
		}
	}
}
