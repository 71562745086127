.main_container{
    padding: 60px 0;
}
.features-tiles .section-inner{
    padding:0 !important;
}
.text_start{
    text-align: left !important;
}
.title_bold{
    font-weight: 600;
}
.font_clas{
    text-transform: lowercase;
}
.font_big{
    font-size: 23px;
}